//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

	head() {
		let _title = 'Covert AI';
		let _description = this.$t.bind(this)("home.description");
		return {
			title: _title,
			meta: [
				{
					name: 'description',
					content: _description,
				}
			]
		}
	},

	mounted(){
		ROUTE.onMounted( this.$store.$device, {
			current : { type : 'home', slug : 'home' },
			prev : null,
			next : null
		});
	},

	transition : {
		mode: 'out-in',
		css: false,
		beforeEnter( el ){
			ROUTE.beforeEnter( el );
		},
		enter( el, done ){
			ROUTE.enter( el, done );
		},
		afterEnter( el ){
			ROUTE.afterEnter( el );
		},
		leave( el, done ){
			ROUTE.leave(el, done);
		},
	}

}
